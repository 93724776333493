<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotConfigManage' }">组态管理</el-breadcrumb-item>
          <el-breadcrumb-item>编辑组态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="组态名称" prop="configName">
                <el-input v-model="form.configName" placeholder="请输入组态名称" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="组态描述" prop="configDesc">
                <el-input type="textarea" v-model="form.configDesc" :rows="5" placeholder="请输入组态描述" maxlength="300" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 组态图 -->
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <div class="form-title">组态图</div>
            <div>
              <el-button type="primary" size="small" @click="viewConfig" :disabled="!cells.cells">预览组态图</el-button>
              <el-button type="primary" size="small" @click="drawConfig">绘制组态图</el-button>
            </div>
          </div>
          <div class="config-tips" v-if="cells.cells">
            <div class="config-text config-check">组态图已绘制</div>
            <div class="tips-icon check">
              <i class="el-icon-check"></i>
            </div>
          </div>
          <div class="config-tips" v-else>
            <div class="config-text config-close">未绘制组态图</div>
            <div class="tips-icon close">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <!-- 预览组态图 -->
          <el-dialog
            :visible.sync="dialogVisible"
            :show-close="false"
            fullscreen
            width="60%">
            <div class="container_warp">
              <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab" @tab-click="handleClick">
                <el-tab-pane class="tab-pane-bg" v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
                  <div v-show="item.identification=='outerChain'">
                    <div :id="'containerDetailChart'+item.name" class="config-container"></div>
                    <div class="model-mask" v-show="isModelMask"></div>
                  </div>
                  <div v-show="item.identification=='historyRecord'">
                    <div class="table-list">
                      <div class="title">
                        <span></span>
                        {{item.title}}
                      </div>
                      <div class="search">
                        <el-form :inline="true" class="demo-form-inline">
                          <el-form-item label="上报时间">
                            <el-date-picker
                              size="small"
                              v-model="item.finishTime"
                              type="daterange"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期">
                            </el-date-picker>
                          </el-form-item>
                          <el-form-item>
                            <el-button size="small" type="primary" @click="findSubmit">查询</el-button>
                          </el-form-item>
                          <el-form-item label="筛选记录">
                            <el-select size="small" v-model="item.filter" clearable placeholder="请选择筛选项" @change="changeFilter">
                              <el-option
                                v-for="fItem in item.filterRecords"
                                :label="fItem.describe"
                                :value="fItem.tag"
                                :key="fItem.tag"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-form>
                      </div>
                      <el-table :ref="item.name" :data="item.tableContent" style="width: 100%;overflow: auto;" :key="editableTabsValue">
                        <el-table-column :class="tItem.tag" :width="item.tableTitle.length<9?'':'200'" v-for="(tItem, tIndex) in item.tableTitle" :key="tIndex" :label="`${tItem.describe}(${tItem.tag})`" :prop="tItem.tag"></el-table-column>
                      </el-table>
                      <page v-if="item.total!==0" :size="item.size" :page="item.page" :total="item.total" v-on:sizeChange="(val)=>{sizeChange(val,item)}" v-on:pageChange="(val)=>{pageChange(val,item)}"></page>
                    </div>
                  </div>
                  <div v-show="item.identification=='alarm'">
                    <div class="table-list">
                      <el-table :data="tableData" style="width: 100%" :key="editableTabsValue">
                        <el-table-column label="序号" type="index" width="50"></el-table-column>
                        <el-table-column prop="waterworks" label="水厂">
                          <template slot-scope="scope">
                            {{ scope.row.waterworks?scope.row.waterworks:'-' }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="position" label="泵房编号">
                          <template slot-scope="scope">
                            {{ scope.row.position?scope.row.position:'-' }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
                        <el-table-column prop="eid" label="设备号"></el-table-column>
                        <el-table-column prop="flowInstantaneous" label="瞬时流量（m³/h）">
                          <template slot-scope="scope">
                            {{ scope.row.content.flowInstantaneous }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="fluidVelocity" label="瞬时流速（m/s）">
                          <template slot-scope="scope">
                            {{ scope.row.content.fluidVelocity }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="positiveCumulativeFlow" label="正累积流量（m³）">
                          <template slot-scope="scope">
                            {{ scope.row.content.positiveCumulativeFlow }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="negativeCumulativeFlow" label="负累积流量（m³）">
                          <template slot-scope="scope">
                            {{ scope.row.content.negativeCumulativeFlow }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="reportTime" label="上报时间">
                          <template slot-scope="scope">
                            {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
                          </template>
                        </el-table-column>
                      </el-table>
                      <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="btn-text-model" v-show="btnTextVisile" v-loading="btnTextLoading" element-loading-spinner="el-icon-loading">
              <div class="model-title">
                <p class="title-text">提示</p>
                <i class="el-icon-close" @click="btnClose"></i>
              </div>
              <div style="display:flex;align-items:center;margin-left:0px;">
                <i class="el-icon-warning" style="color:#E6A23C;font-size:20px;margin: 0 5px;"></i>
                <p class="btn-content-text">请确认是否点击按钮发送指令?</p>
              </div>
              <div class="model-fotter">
                <el-button type="primary" size="mini" @click="btnClose">关闭</el-button>
                <el-button type="primary" size="mini" @click="sendBtnInstruction">发送</el-button>
              </div>
            </div>
            <div class="edit-text-model" v-show="editTextVisile" v-loading="editTextLoading" element-loading-spinner="el-icon-loading">
              <div class="model-title">
                <p class="title-text">{{ inputTitle }} 指令下发</p>
                <i class="el-icon-close" @click="inputValueClose"></i>
              </div>
              <div class="model-input">
                <el-input v-model="inputValue" placeholder="请输入数值"></el-input>
              </div>
              <div class="model-fotter">
                <el-button type="primary" size="mini" @click="inputValueClose">关闭</el-button>
                <el-button type="primary" size="mini" @click="sendInputValue">发送</el-button>
              </div>
            </div>
          </el-dialog>
          
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotConfigManage')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
import '@antv/x6-vue-shape'
import { Graph,Shape,FunctionExt,DataUri} from '@antv/x6';
import insertCss from 'insert-css';
import { handleDeviceNode } from '@/js/Graph/configDetails';
import { reportCache } from '@/js/Graph/reportCache';
import { waterLinearAnmi } from '@/js/Graph/waterLinearAnimation';
import { waterLevel } from '@/js/Graph/waterLevelAnimation';
import { calcRate } from '@/js/Graph/calcPixels';
import AlarmPopup from '../../components/AlarmPopup';
const data = {};
export default {
  components: {
    AlarmPopup,
    page,
  },
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        configName:[{ required: true, message: '必填项', trigger: 'blur' }],
        configDesc:[{ required: true, message: '必填项', trigger: 'blur' }],
      },
      cells: {},

      // 预览组态
      row: null,
      cellData: {},
      graph:'',
      type:'grid',
      selectCell:'',
      connectEdgeType:{  //连线方式
        connector: 'normal',
        router: {
          name: ''
        }
      },
      grid:{ // 网格设置
        size: 12,      // 网格大小 10px
        visible: false, // 渲染网格背景
        type: 'dot',
        args: {
          color: '#D0D0D0',
          thickness: 1,     // 网格线宽度/网格点大小
          factor: 10,
        },
      },
      wsdata: {},

      // 选项卡
      editableTabsValue: null,
      editableTabs: [],

      dialogVisible: false,
      canvasURL: '',
      editTextVisile: false,
      inputTitle: null,
      inputValue: null,
      editTextObj: {},
      editTextLoading: false,
      
      btnTextVisile: false,
      btnTextLoading: false,
      btnTextObj: {},

      isModelMask:false,
      tabLabel: null,
      tabName: null,
      size: 50,
      page: 1,
      total: 0,
      tableData: [],
      titleArr: [],
      contentArr: [],
      formInline: {
        finishTime:[]
      },
      tempWsData:[]
    };
  },
  methods: {
    dayjs,
    // base64转file文件
    base64ToFile(base64, fileName) {
      let arr = base64.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while(n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], fileName, { type: mime });
    },
    // 图片地址转base64编码
    getBase64Image(img) {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();
        var dataURL = canvas.toDataURL('image/'+ext);
        return dataURL
    },
    // 提交数据
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let formData = {
            cells: this.cells.cells,
            name: this.form.configName,
            describe: this.form.configDesc,
            projectId: sessionStorage.getItem('configProjectId'),
            configurationId: sessionStorage.getItem('configurationId'),
          }
          if(!formData.cells) {
            return this.$message.error('请绘制组态图')
          }
          console.log(formData)

          this.loading = true
          this.$ajax.post("configurationUpdate", formData).then((res) => {
            if(res.code==0) {
              if(this.canvasURL) {
                let configFile = this.base64ToFile(this.canvasURL, this.form.configName+'.png')
                let formFile = new FormData()
                formFile.append('projectId',sessionStorage.getItem('configProjectId'))
                formFile.append('configurationId',sessionStorage.getItem('configurationId'))
                formFile.append('coverFile',configFile)

                this.$ajax.post("configUpdateCover", formFile).then((cover) => {
                  if(cover.code==0) {
                    this.loading = false
                    this.$message.success("成功");
                    this.$router.push("/iotConfigManage");
                  }else {
                    this.loading = false
                    this.$message.error("失败");
                  }
                })
              }else {
                this.loading = false
                this.$message.success("成功");
                this.$router.push("/iotConfigManage");
              }
            }else {
              this.loading = false
              this.$message.error("失败");
            }
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    // 绘制组态
    drawConfig() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          sessionStorage.setItem('configForm',JSON.stringify(this.form))
          this.$router.push({ name: 'iotConfigManageConfigEdit' })
        }
      })
    },
    // 预览组态
    viewConfig() {
      this.dialogVisible = true
      let inIs = this.editableTabs.find((v) => {
        return v.name == this.row
      })
      if(inIs) {
        this.editableTabsValue = this.row
      }else {
        this.firstViewConfig()
      }
    },
    findSubmit() {
      this.getRecordList()
    },

    // 获取历史记录列表
    async getRecordList() {
      await this.loadRecordTableTitle()
      await this.loadRecordTableContent()
    },

    // 获取历史记录表头
    loadRecordTableTitle() {
      return new Promise((resolve,reject)=>{
        this.editableTabs.forEach(editTab=>{
          if(this.editableTabsValue==editTab.name) {
            let indexOf = this.editableTabsValue.indexOf(' ')
            let configurationId = this.editableTabsValue.substring(0,indexOf)
            this.$ajax.post('reportConfigTitle', {
              configurationId: configurationId
            }).then(res => {
              this.titleArr = []
              res.data.forEach(v=>{
                v.titles.forEach(t=>{
                  this.titleArr.push(t)
                })
              })
              editTab.tableTitle = this.titleArr
              editTab.filterRecords = this.titleArr
              resolve()
            })
          }
        })
      })
    },

    // 获取历史记录内容
    loadRecordTableContent() {
      return new Promise((resolve,reject)=>{
        this.editableTabs.forEach(editTab=>{
          if(this.editableTabsValue==editTab.name) {
            // 获取昨天的时间戳
            let yDate = new Date()
            let year = yDate.getFullYear();
            let month = yDate.getMonth()+1;
            let date = yDate.getDate()-1;
            let yesterday = year+'-'+month+'-'+date
            let yesterdayTime = (new Date(new Date(yesterday).toLocaleDateString())).getTime();
            // 获取当天的时间戳
            let todayTime = (new Date(new Date().toLocaleDateString())).getTime();
            let dates = [] 
            if(editTab.finishTime){
              editTab.finishTime.forEach((i)=>{
                var date = new Date(i)
                dates.push(date.getTime()) 
              })
            }
            let indexOf = this.editableTabsValue.indexOf(' ')
            let configurationId = this.editableTabsValue.substring(0,indexOf)
            this.$ajax.post('reportConfigRecord', {
              configurationId: configurationId,
              startTime: dates.length>0?dates[0]:yesterdayTime,
              endTime: dates.length>0?dates[1]:todayTime,
              pageNum: editTab.page,
              pageSize: editTab.size, 
            }).then(res => {
              this.contentArr = []
              res.data.forEach(v=>{
                v.attributes.forEach((rItem,rIndex)=>{
                  if(this.contentArr.length>=this.size) {
                    this.contentArr.forEach((tItem,tIndex)=>{
                      if(rIndex==tIndex) {
                        rItem.forEach(vItem=>{
                          tItem[vItem.tag] = vItem.tagValue?vItem.tagValue:'-'
                        })
                      }
                    })
                  }else {
                    let obj = {}
                    rItem.forEach(vItem=>{
                      obj[vItem.tag] = vItem.tagValue?vItem.tagValue:'-'
                    })
                    this.contentArr.push(obj)
                  }
                })
              })
              editTab.tableContent = this.contentArr
              editTab.total = res.total
              resolve()
            })
          }
        })
      })
    },
    // Tag过滤筛选
    changeFilter(v) {
      this.getTableScroll(v)
    },

    getTableScroll(tag) {
      const contentTables = this.$refs
      this.$nextTick(()=>{
        for(let i in contentTables) {
          this.editableTabs.forEach(v=>{
            if(i==v.name&&v.identification=='historyRecord') {
              v.tableTitle.forEach((titleItem,titleIndex)=>{
                if(tag==titleItem.tag) {
                  contentTables[i].forEach(comp=>{
                    let positionJump = titleIndex * 200 
                    console.log(positionJump)
                    setTimeout(()=>{
                      comp.bodyWrapper.scrollLeft = positionJump
                    },300)
                  })
                }
              })
            }
          })
        }
      })
    },
    // tab切换事件
    handleClick(tab) {
      console.log(tab.label)
      console.log(tab.name)
      this.tabLabel = tab.label 
      this.tabName = tab.name 
    },
    // 删除tab
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      let activeLabel = this.tabLabel;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
              activeLabel = nextTab.title;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.tabName = activeName;
      this.tabLabel = activeLabel;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);
      if(this.editableTabs.length==0) {
        this.dialogVisible = false
      }
    },
    // 初始化画布
    initX6(configurationId){
      var _that = this
      this.graph = new Graph({
          container: document.getElementById('containerDetailChart'+configurationId),
          width: '100%',
          height: '100vh',
          grid: _that.grid,
          panning: {
            enabled: false,//开启平移
            modifiers: 'ctrl',//如何其他操作有冲突,可用修饰参数触发
            eventTypes: ['leftMouseDown','rightMouseDown','mouseWheel'],//设置触发画布的拖拽行为,默认为['leftMouseDown']          
          },
          scroller: {
            enabled: true,
            pannable: true,
            pageVisible: false,
            pageBreak: false,
          },
          mousewheel: {
            enabled: true,//开启滚轮缩放
            // modifiers: ['ctrl','meta'],
          },
          resizing: { //调整节点宽高
            enabled: false,
            orthogonal:false,
          }, 
          translating: {//限制节点移动范围(不能超出画布)
            restrict: true,
          },
          snapline:  false,//开启对齐线
          interacting: {//节点拖动
            nodeMovable: false,//禁止节点拖动
            edgeLabelMovable: false,//禁止边拖动
          },
          selecting: {
            enabled: false,//开启点选框选
            multiple: true,
            rubberband: true,
            strict: true,
          },
          connecting: { // 节点连接
            anchor: 'center',
            connectionPoint: 'anchor',
            allowBlank: false,
            snap: true,
            createEdge () {
              return new Shape.Edge({
                attrs: {
                  line: {
                    stroke: '#1890ff',
                    strokeWidth: 1,
                    targetMarker: {
                      name: 'classic',
                      size: 8
                    },
                    strokeDasharray: 0, //虚线
                    style: {
                      animation: 'ant-line 30s infinite linear',
                    },
                  },
                },
                label: {
                  text:''
                },
                connector: _that.connectEdgeType.connector,
                router: {
                  name: _that.connectEdgeType.router.name || ''
                },
                zIndex: 0
              })
            },
          },
          highlighting: {
            magnetAvailable: {
              name: 'stroke',
              args: {
                padding: 4,
                attrs: {
                  strokeWidth: 4,
                  stroke: '#6a6c8a'
                }
              }
            }
          },
      });
      insertCss(`
        @keyframes ant-line {
          to {
              stroke-dashoffset: -1000
          }
        }
      `)
      this.graph.fromJSON(data)
      this.graph.history.redo()
      this.graph.history.undo()
      this.graph.centerContent()
      // 鼠标移入移出节点
      this.graph.on('node:mouseenter',FunctionExt.debounce(() => {
        const container =  document.getElementById('containerDetailChart'+configurationId)
        const ports = container.querySelectorAll('.x6-port-body')
        this.showPorts(ports, false)
      }),500)
      this.graph.on('node:mouseleave', () => {
        const container =  document.getElementById('containerDetailChart'+configurationId)
        const ports = container.querySelectorAll('.x6-port-body')
        this.showPorts(ports, false)
      })
      this.graph.on('blank:click', ({ e }) =>{
        if(this.btnTextLoading==false) {
          this.btnTextVisile = false
        }
        this.editTextVisile = false
      })
      // 监听cell点击事件
      this.graph.on('cell:click', ({ cell }) => {
        this.type = cell.isNode() ? 'node' : 'edge'
        let cellData = cell.store.data.data
        this.btnTextVisile = false
        this.editTextVisile = false
        this.isModelMask = false
        // 如果是按钮
        if(cellData&&cellData.graphical.action==0) {
          this.btnTextObj = cellData
          this.btnTextVisile = true
          this.isModelMask = true
        }
        // 如果是开关
        if(cellData&&cellData.graphical.action==1) {
          this.btnTextObj = cellData
          this.btnTextVisile = true
          this.isModelMask = true
        }
        // 如果是外链
        if(cellData&&cellData.graphical.action==2) {
          this.row = cellData.graphical.configurationIdOutsideLink
          let inIs = this.editableTabs.find((v) => {
            return v.name == this.row
          })
          if(inIs) {
            this.editableTabsValue = this.row
            let tabTitle = this.editableTabs.find((ve) => {
              return ve.name == this.editableTabsValue
            })
            this.tabName = this.editableTabsValue
            this.tabLabel = tabTitle.title
          }else {
            this.loadDetailData()
          }
        }
        if(cellData&&cellData.graphical.action==3) {
            let inIs = this.editableTabs.find((ve) => {
              return ve.name == this.tabName+' - 历史记录'
            })
            if(inIs) {
              this.editableTabsValue = this.tabName+' - 历史记录'
            }else {
              this.editableTabs.push({
                identification: 'historyRecord',
                title: this.tabLabel+' - 历史记录',
                name: this.tabName+' - 历史记录',
                tableContent: [],
                tableTitle: [],
                size: 50,
                page: 1,
                total: 0,
                finishTime:[],
                filterRecords: [],
                filter: '',
              })
              this.editableTabsValue = this.tabName+' - 历史记录'
            }
            this.getRecordList()
        }
        if(cellData&&cellData.graphical.action==4) {
            let inIs = this.editableTabs.find((ve) => {
              return ve.name == this.tabName+' - 告警'
            })
            if(inIs) {
              this.editableTabsValue = this.tabName+' - 告警'
            }else {
              this.editableTabs.push({
                identification: 'alarm',
                title: this.tabLabel+' - 告警',
                name: this.tabName+' - 告警',
              })
              this.editableTabsValue = this.tabName+' - 告警'
            }
        }
        // 如果为编辑文本
        if(cellData&&cellData.graphicalType=='editText') {
          console.log(cellData)
          this.inputTitle = cellData.graphical.name
          this.inputValue = null
          this.editTextObj = cellData.graphical
          this.editTextObj.gatewayEid = cellData.gatewayEid
          this.editTextObj.eid = cellData.eid
          this.editTextVisile = true
          this.isModelMask = true
        }
      })

      // 监听node鼠标按下事件
      this.graph.on('node:mousedown', ({ node }) => {
        let nodeData = node.store.data.data
        if(nodeData&&nodeData.graphicalType=='button'&&nodeData.graphical.action==0) {
          // console.log('button鼠标按下')
        }
      })

      // 监听node鼠标松开事件
      this.graph.on('node:mouseup', ({ node }) => {
        let nodeData = node.store.data.data
        if(nodeData&&nodeData.graphicalType=='button'&&nodeData.graphical.action==0) {
          // console.log('button鼠标抬起')
        }
      })

      // 屏幕自适应
      window.addEventListener('resize', this.resize)

      this.graph.on('selection:changed', (args) => {
        args.added.forEach(cell => {
          this.selectCell = cell
          if(cell.isEdge()){
            cell.isEdge() && cell.attr('line/strokeDasharray', 5) //虚线蚂蚁线
            cell.addTools([
              {
                name: 'vertices',
                args: {
                  padding: 4,
                  attrs: {
                    strokeWidth: 0.1,
                    stroke: '#2d8cf0',
                    fill: '#ffffff',
                  }
                },
              },
            ])
          }
        })
        args.removed.forEach(cell => {
          cell.isEdge() && cell.attr('line/strokeDasharray', 0)  //正常线
          cell.removeTools()
        })
      })
    },
    showPorts (ports, show) {
      for (let i = 0, len = ports.length; i < len; i = i + 1) {
        ports[i].style.visibility = show ? 'visible' : 'hidden'
      }
    },

    // 计算屏幕缩放
    resize() {
      const configContainer = document.querySelectorAll('.config-container')
      calcRate(configContainer)
    },
    
    // 首次预览组态,未将组态图提交给接口
    firstViewConfig() {
      this.editableTabs.push({
        identification: 'outerChain',
        title: this.form.configName,
        name: this.row,
      })
      this.editableTabsValue = this.row
      this.tabLabel = this.form.configName
      this.tabName = this.row
      setTimeout(() => {
        // 组态结构全局赋值
        this.initX6(this.row)
        // 将接口数据渲染至画布上
        this.graph.fromJSON(this.cells.cells)
        // 屏幕自适应
        this.resize()
        // 获取最新缓存数据
        this.loadReportCache(this.cells)
        // 隐藏链接桩port
        const container =  document.getElementById('containerDetailChart'+this.row)
        const ports = container.querySelectorAll('.x6-port-body')
        this.showPorts(ports, false)
        // 获取所有画布上节点
        let allNodes = this.graph.getNodes()
        //循环画布上所有节点
        allNodes.forEach(v=>{
          if(v.store.data.data&&JSON.stringify(v.store.data.data.graphical)=='{}') {
            // v.attr('body/fill', 'none')
          }
          if(v.store.data.data&&v.store.data.data.withData==0) {
            if(v.store.data.data.graphicalType=='dynamicPicture') {
              v.attr('svgele/class', 'hideNode')
              if(v.store.data.data.graphical.customAnimations) {
                v.store.data.data.graphical.customAnimations.forEach(anV=>{
                  if(anV.animation=='textChange') {
                    v.attr('body/class', 'hideNode')
                    v.attr('label/class', 'hideNode')
                    v.attr('image/class', 'hideNode')
                  }
                })
              }
            }
          }
          if(v.store.data.data&&v.store.data.data.withData==1) {
            if(v.store.data.data.graphical.customAnimations) {
              v.store.data.data.graphical.customAnimations.forEach(anV=>{
                if(anV.withoutData=='execute') {//默认执行动画
                  waterLinearAnmi(v)
                  v.attr('svgele/class', 'showNode')
                }else if(anV.withoutData=='noExecute') {//默认不执行
                  v.attr('svgele/class', 'hideNode')
                }
                if(anV.animation=='waterLevel') {//普通节点液位动画
                  let numItemVal= Number(anV.waterLevel.defaultValue)
                  let maxVal= Number(anV.waterLevel.max)
                  let calculate = (numItemVal/maxVal)*100
                  //液位动画
                  waterLevel(v,calculate)
                }
              })
            }
          }
        })
        this.connectWebsocket()
        this.handleList()
      },0)
    },
    // 组态详情接口
    loadDetailData() {
      this.$ajax.post('configurationDetail', {}, { configurationId: this.row }).then(res => {
        this.editableTabs.push({
          identification: 'outerChain',
          title: res.data.name,
          name: res.data.configurationId,
        })
        this.editableTabsValue = res.data.configurationId
        this.tabLabel = res.data.name
        this.tabName = res.data.configurationId
        this.row = res.data.configurationId
        setTimeout(()=>{
          // 组态结构全局赋值
          this.initX6(res.data.configurationId)
          this.cellData = res.data
          // 将接口数据渲染至画布上
          this.graph.fromJSON(this.cellData.cells)
          // 屏幕自适应
          this.resize()
          // 获取最新缓存数据
          this.loadReportCache(this.cellData)

          // 隐藏链接桩port
          const container =  document.getElementById('containerDetailChart'+res.data.configurationId)
          const ports = container.querySelectorAll('.x6-port-body')
          this.showPorts(ports, false)
          // 获取所有画布上节点
          let allNodes = this.graph.getNodes()
          //循环画布上所有节点
          allNodes.forEach(v=>{
            if(v.store.data.data&&JSON.stringify(v.store.data.data.graphical)=='{}') {
              // v.attr('body/fill', 'none')
            }
            v.attr('text/fontFamily', 'SourceHanSerifCN-Bold')
            if(v.store.data.data&&v.store.data.data.withData==0) {
              if(v.store.data.data.graphicalType=='dynamicPicture') {
                v.attr('svgele/class', 'hideNode')
                if(v.store.data.data.graphical.customAnimations) {
                  v.store.data.data.graphical.customAnimations.forEach(anV=>{
                    if(anV.animation=='textChange') {
                      v.attr('body/class', 'hideNode')
                      v.attr('label/class', 'hideNode')
                      v.attr('image/class', 'hideNode')
                    }
                  })
                }
              }
            }
            if(v.store.data.data&&v.store.data.data.withData==1) {
              if(v.store.data.data.graphical.customAnimations) {
                v.store.data.data.graphical.customAnimations.forEach(anV=>{
                  if(anV.withoutData=='execute') {//默认执行动画
                    waterLinearAnmi(v)
                    v.attr('svgele/class', 'showNode')
                  }else if(anV.withoutData=='noExecute') {//默认不执行
                    v.attr('svgele/class', 'hideNode')
                  }
                  if(anV.animation=='waterLevel') {//普通节点液位动画
                    let numItemVal= Number(anV.waterLevel.defaultValue)
                    let maxVal= Number(anV.waterLevel.max)
                    let calculate = (numItemVal/maxVal)*100
                    //液位动画
                    waterLevel(v,calculate)
                  }
                })
              }
            }
          })
          this.connectWebsocket()
          this.handleList()
        },0)
      })
    },
    // 点击按钮下发数据
    buttonPushData(gatewayEid,eid,downTag,downTagValue,upTag,upTagValue) {
      this.btnTextLoading = true
      setTimeout(()=>{
        this.btnTextVisile = false
        this.btnTextLoading = false
        this.isModelMask = false
      },500)
      this.$ajax.post("configButtonPushData", {
        gatewayEid: gatewayEid,
        eid: eid,
        downTag: downTag,
        downTagValue: downTagValue,
        upTag: upTag,
        upTagValue: upTagValue,
        projectId: sessionStorage.getItem('configProjectId'),
        configurationId: sessionStorage.getItem('configurationId'),
      }).then((res) => {
        if(res.code!=0) {
          this.$message.error('下发失败');
        }
      }).catch(err=>{
        this.btnTextVisile = false
        this.btnTextLoading = false
        this.isModelMask = false
        this.$message.error(err.msg);
      })
    },

    // 点击开关下发数据
    switchPushData(gatewayEid,eid,tag,tagValue) {
      this.btnTextLoading = true
      setTimeout(()=>{
        this.btnTextVisile = false
        this.btnTextLoading = false
        this.isModelMask = false
      },500)
      this.$ajax.post("configSwitchPushData", {
        gatewayEid: gatewayEid,
        eid: eid,
        tag: tag,
        tagValue: tagValue,
        projectId: sessionStorage.getItem('configProjectId'),
        configurationId: sessionStorage.getItem('configurationId'),
      }).then((res) => {
        if(res.code!=0) {
          this.$message.error('下发失败');
        }
      }).catch(err=>{
        this.btnTextVisile = false
        this.btnTextLoading = false
        this.isModelMask = false
        this.$message.error(err.msg);
      })
    },

    // 关闭按钮弹框
    btnClose() {
      this.btnTextVisile = false
      this.isModelMask = false
    },

    // 点击弹框确定按钮下发指令
    sendBtnInstruction() {
      console.log(this.btnTextObj)
      let gatewayEid = this.btnTextObj.gatewayEid
      let eid = this.btnTextObj.eid
      if(this.btnTextObj.graphical.action==0) {
        let downTag = this.btnTextObj.graphical.downTag
        let downTagValue = this.btnTextObj.graphical.downTagValue
        let upTag = this.btnTextObj.graphical.upTag
        let upTagValue = this.btnTextObj.graphical.upTagValue
        this.buttonPushData(gatewayEid,eid,downTag,downTagValue,upTag,upTagValue)
      }
      if(this.btnTextObj.graphical.action==1) {
        let pushTag = this.btnTextObj.graphical.pushTag
        let pushTagValue = this.btnTextObj.graphical.pushTagValue
        this.switchPushData(gatewayEid,eid,pushTag,pushTagValue)
      }
    },

    // 保留小数点后N为,并补零
    changeDecimal(number,bitNum) {
      let f_x = parseFloat(number);
      if(isNaN(f_x)) {
        return 0;
      }
      let s_x = number.toString();
      let pos_decimal =s_x.indexOf('.');
      if(pos_decimal<0) {
        pos_decimal = s_x.length;
        if(bitNum!=0) {
          s_x += '.';
        }else {
          return s_x;
        }
      }
      if(s_x.length <= pos_decimal + bitNum) {
        while(s_x.length <= pos_decimal + bitNum) {
          s_x += '0';
        }
      }else {
        s_x = s_x.substring(0,pos_decimal + bitNum + 1)
      }
      return s_x;
    },

    // 关闭编辑文本弹框
    inputValueClose() {
      this.editTextVisile = false
      this.isModelMask = false
    },

    // 发送指令
    sendInputValue() {
      let max = Number(this.editTextObj.max)
      let min = Number(this.editTextObj.min)
      let pointBehindNum = Number(this.editTextObj.pointBehindNum)
      if(!this.inputValue) {
        return this.$message.error('请输入下发数值')
      }
      if(this.inputValue<min||this.inputValue>max) {
        return this.$message.error('请输入设定范围的数值')
      }
     
      // let tagValue = this.changeDecimal(this.inputValue,pointBehindNum)
      this.editTextLoading = true
      setTimeout(()=>{
        this.editTextVisile = false
        this.editTextLoading = false
        this.isModelMask = false
      },500)
      this.$ajax.post("configOutputPushData",{
        gatewayEid: this.editTextObj.gatewayEid,
        eid: this.editTextObj.eid,
        tag: this.editTextObj.tag,
        tagValue: this.inputValue,
        projectId: sessionStorage.getItem('configProjectId'),
        configurationId: sessionStorage.getItem('configurationId'),
      }).then(res=>{
        if(res.code!=0) {
          this.$message.error('发送失败');
        }
      }).catch(err=>{
        if(err.code==1) {
          this.editTextVisile = false
          this.editTextLoading = false
          this.isModelMask = false
          this.$message.error(err.msg);
        }
      })
    },
    // 连接websocket
    connectWebsocket() {
      // 客户端收到服务的返回的数据
      this.$globalWs.ws.onmessage = e => {
        if(e.data.indexOf('pong') > -1) {
          // 心跳回复——心跳检测重置
          // 收到心跳检测回复就说明连接正常
          console.log("收到心跳检测回复");
          // 心跳检测重置
          this.$globalWs.heartCheck.reset().start(this.$globalWs.ws);
        }else {
          let data = JSON.parse(e.data)
          let message = JSON.parse(data.message)
          // 获取所有画布上节点
          let allNodes = this.graph.getNodes()
          let find = allNodes.some(v=>v.store.data.data&&message.subEid==v.store.data.data.eid)
          if(find) {
            this.tempWsData.push(data)
          }
        }
      }
    },
    // 通过缓存数组处理websocket卡顿
    handleList() {
      this.wsInterval = setInterval(()=>{
        let tempLastData = this.tempWsData[this.tempWsData.length-1]
        if(!tempLastData) return
        let dmessage = JSON.parse(tempLastData.message)
        // 推送消息为组态数据
        if(tempLastData.messageType=='configurationMessage') {
          handleDeviceNode(this.graph, dmessage, this.editableTabsValue)
        }
        this.tempWsData = []
      },5000)
    },
    // 获取最新组态缓存数据
    loadReportCache(cellData) {
      let eids = []
      for(let i=0;i<cellData.cells.length;i++) {
        let data = cellData.cells[i].data
        if(data&&data.eid) {
          let inIs = eids.find(function(eid) { return eid == data.eid })
          if (!inIs) { eids.push(data.eid) }
        }
      }
      this.$ajax.post('configFirstReportCache', {
        eids: eids
      }).then(res => {
        res.data.forEach(wsdata=>{
          reportCache(this.graph, wsdata, this.editableTabsValue)
        })
      })
    },
    // 调用详情接口,给默认赋值
    getConfigDetail() {
      this.loading = true
      this.$ajax.post('configurationDetail', {}, { configurationId: sessionStorage.getItem('configurationId') }).then(res => {
        let form = {
            configName: res.data.name,
            configDesc: res.data.describe,
        }
        let cells = {
            cells: res.data.cells
        }
        this.form = form
        this.cells = cells

        this.canvasURL = null

        sessionStorage.setItem('configForm', JSON.stringify(this.form))
        sessionStorage.setItem('configCellsData', JSON.stringify(this.cells))
        sessionStorage.setItem('canvasURL', this.canvasURL)
        this.loading = false
      })
    },
    pageChange(val,item) {
      item.page = val
      sessionStorage.setItem('currentPage', val)
      this.getRecordList()
    },
    sizeChange(val,item) {
      item.size = val
      sessionStorage.setItem('currentSize', val)
      this.getRecordList()
    },
  },
  mounted() {
   if(sessionStorage.getItem('configForm')) {//可以找到缓存的值
    let form = sessionStorage.getItem('configForm')
    let cells = sessionStorage.getItem('configCellsData')
    
    this.form = JSON.parse(form)
    this.cells = JSON.parse(cells)
   }else {//没找到缓存的值,默认请求详情接口赋值
    this.getConfigDetail()
   }
   if(sessionStorage.getItem('canvasURL')) {//可以找到缓存的值
      this.canvasURL = sessionStorage.getItem('canvasURL')
   }else {//没找到缓存的值,默认请求详情接口赋值
    this.getConfigDetail()
   }
   if (this.$route.params.row != null) {
      this.row = this.$route.params.row.configurationId
    }
  },
  destroyed() {
    clearInterval(this.wsInterval)
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.config-tips {
  width: 100%;
  height: 60px;
  border: 1px solid rgb(244, 244, 244);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  box-shadow: 0px 0px 10px rgba(211, 211, 211, .3);
  border-radius: 10px;
  margin-top: 20px;
  padding: 0 20px;
  box-sizing: border-box;
}
.tips-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 34px;
  color: #FFF;
  font-size: 22px;
}
.check {
  background: #67c23a;
}
.close {
  background: #c4c4c5;
} 
.config-text {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  line-height: 30px;
}
.config-check {
  background: #e2f6d9;
  color: #67c23a;
}
.config-close {
  background: #ededed;
  color: #828282;
}
/deep/.el-dialog__body {
  padding: 0px 10px;
}
/deep/.el-dialog__header {
  padding: 0;
}
/deep/.el-tabs__header {
  margin: 0;
  border: 0;
  user-select: none;
}
/deep/.el-tabs__item.is-active {
  color: #FFF;
  background: #409EFF;
}
/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #409EFF;
}
/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  border: 0px solid #E4E7ED;
  margin-bottom: 1px;
  background: #f1f8ff;
}
/deep/.el-tabs--card>.el-tabs__header .el-tabs__item .el-icon-close {
  width: 14px;
}
/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-closable:hover {
  padding-left: 20px;
  padding-right: 20px;
}
.search {
  padding: 10px;
}
</style>
<style lang="less">
  @import '../../css/iotConfigExhib/detail.less';
</style>